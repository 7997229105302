import { useContext } from 'react'

import { NewsContext } from 'templates/post'

const useNews = () => {
  const context = useContext(NewsContext)

  return context
}

export default useNews
