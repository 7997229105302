import React from 'react'

// Components
import ParseContent from 'components/shared/ParseContent'
import ButtonTransparentArrow from 'components/elements/Buttons/ButtonTransparentArrow'
import ButtonDefaultArrow from 'components/elements/Buttons/ButtonDefaultArrow'
import Plaatjie from '@ubo/plaatjie'

import Blogik, { BlogConsumer, BlogFilter } from 'components/shared/Blogik'

// Third Party
import styled, { css } from 'styled-components'

interface ProjectsProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Blog
  location: any
}

const Amount = styled.div`
  font-weight: ${({ theme }) => theme.font.weight.bold};
  color: ${({ theme }) => theme.color.dark};
`

const Shadow = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  border-radius: 25px;
  transition: all 0.3s ease;
`

const Block = styled.div<{ active: boolean }>`
  position: relative;
  border-radius: 25px;
  background-color: ${({ theme }) => theme.color.light};
  color: ${({ theme }) => theme.color.secondary};
  transition: all 0.3s ease;
  height: 100%;
  cursor: pointer;

  ${Shadow} {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  }

  ${({ active, theme }) =>
    active &&
    css`
      box-shadow: inset 0 0 0 4px ${theme.color.secondary};
    `}

  & img {
    max-height: 80px;
  }

  &:hover {
    /* box-shadow: inset 0 0 0 4px ${({ theme }) => theme.color.secondary}; */
    ${Shadow} {
      box-shadow: 0 0 0px rgba(0, 0, 0, 0.3);
    }
  }
`

const FilterTitle = styled.div`
  font-weight: ${({ theme }) => theme.font.weight.bold};
  color: ${({ theme }) => theme.color.secondary};
`

const Projects: React.FC<ProjectsProps> = ({ fields }) => (
  <section className="mt-5">
    <Blogik
      type={fields?.viewtype}
      settings={{
        id: `${fields.viewtype}-blogik`,
        limit: Number(fields.limit),
        type: 'projecten',
      }}
    >
      <div className="py-4">
        {fields.viewtype === 'blog' && <BlogFilters />}
        {fields.viewtype === 'vacatures' && (
          <div className="container">
            <BlogConsumer>
              {({
                posts,
                categories,
                selectedFilters,
                setSelectedFilters,
              }: any) => {
                const setFilter = (id: number) => {
                  setSelectedFilters(id)
                }

                return (
                  <div className="row pt-5">
                    <div className="col-lg-5 mb-5">
                      <ParseContent content={fields.description} />
                    </div>
                    <div className="col-lg-7 mb-5 d-flex align-items-center justify-content-end">
                      <div>
                        <FilterTitle className="pb-4">
                          Filter onze vacatures op functie:
                        </FilterTitle>
                        <div className="row">
                          {categories.map((category: any, index: number) => (
                            <div
                              className="col-md-4 col-6 mb-4 mb-md-0"
                              // eslint-disable-next-line react/no-array-index-key
                              key={index}
                            >
                              <Block
                                active={selectedFilters.includes(
                                  category.node.id
                                )}
                                onClick={() => setFilter(category.node.id)}
                                className="d-flex flex-column align-items-center px-5 pt-4 pb-4"
                              >
                                <Shadow />
                                <img
                                  src={
                                    category.node.projecttype.image.localFile
                                      .publicURL
                                  }
                                  alt="Icoon"
                                />
                                <span className="mt-auto pt-4">
                                  {category.node.name}
                                </span>
                              </Block>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="col-12 py-3">
                      <div className="row">
                        <div className="col-8">
                          <div className="h-100 d-flex align-items-center">
                            <ParseContent content={fields.alertdescription} />
                          </div>
                        </div>
                        <div className="col-4 d-flex justify-content-end">
                          <ButtonDefaultArrow to={fields.alertlink?.url || '/'}>
                            {fields.alertlink?.title}
                          </ButtonDefaultArrow>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 pt-5">
                      <Amount className="pb-4">{`${posts.length} vacatures`}</Amount>
                    </div>
                  </div>
                )
              }}
            </BlogConsumer>
          </div>
        )}
        <BlogGrid fields={fields} />
      </div>
    </Blogik>
  </section>
)

const StyledBlogFilter = styled(BlogFilter)<{ selected: boolean }>`
  font-weight: ${(props) =>
    props.selected
      ? props.theme.font.weight.bold
      : props.theme.font.weight.light};
`

interface BlogFiltersProps {}

interface BlogFilterProps {
  node: {
    id: string
    name: string
  }
}

const BlogFilters: React.FC<BlogFiltersProps> = () => (
  <BlogConsumer>
    {({ categories, isSelected }: any) => (
      <div className="container d-flex">
        {categories.map((category: BlogFilterProps) => (
          <StyledBlogFilter
            key={category.node.id}
            id={category.node.id}
            selected={isSelected(category.node.id)}
            className="mr-3"
          >
            {category.node.name}
          </StyledBlogFilter>
        ))}
      </div>
    )}
  </BlogConsumer>
)

interface BlogGridProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Blog
}

const BlogGrid: React.FC<BlogGridProps> = ({ fields }) => (
  <BlogConsumer>
    {({ posts }: any) => (
      <div className="container mb-5 pb-5">
        <div className="row">
          {posts.map((post: BlogGridPostProps) => (
            <div key={post.node.id} className="col-lg-6 py-3">
              <BlogGridPost node={post.node} blogFields={fields} />
            </div>
          ))}
        </div>
        {/*
        {showMoreButton && (
          <div className="mt-5 text-center">
            <ButtonDefault to="/" isCustom>
              <BlogButton>{fields.loadMoreText}</BlogButton>
            </ButtonDefault>
          </div>
        )} */}
      </div>
    )}
  </BlogConsumer>
)

const PostWrapper = styled.div`
  background: ${(props) => props.theme.color.light};
  border-radius: 20px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  padding: 140px 80px 20px 80px;
  margin: 100px 40px 40px 40px;

  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    color: ${(props) => props.theme.color.secondary};
  }

  & h4 {
    font-size: 20px;
    font-family: ${({ theme }) => theme.font.family.main};
    font-weight: ${({ theme }) => theme.font.weight.extraBold};
  }

  & h5 {
    font-size: 16px;
    font-family: ${({ theme }) => theme.font.family.main};
  }

  & p {
    line-height: 30px;
    font-weight: ${({ theme }) => theme.font.weight.light};
    font-family: ${({ theme }) => theme.font.family.secondary};
    color: ${(props) => props.theme.color.alternate};
  }

  @media screen and (max-width: 991px) {
    margin: 100px 20px 20px 20px;
  }

  @media screen and (max-width: 575px) {
    padding: 140px 40px 20px 40px;
  }

  @media screen and (max-width: 350px) {
    margin: 100px 0 20px 0;
    padding: 140px 20px 10px 20px;
  }
`

interface BlogGridPostProps {
  // eslint-disable-next-line
  blogFields: GatsbyTypes.WpPage_Flexcontent_Flex_Blog
  node: GatsbyTypes.WpProject
}

const ImageWrapper = styled.div`
  display: flex;
  width: 220px;
  height: 220px;
  position: relative;
  border: 5px solid ${({ theme }) => theme.color.secondary};
  background-color: ${({ theme }) => theme.color.light};
  border-radius: 110px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden !important;

  & img {
    border-radius: 110px;
  }
`

const Line = styled.div`
  height: 1px;
  background-color: ${({ theme }) => theme.color.secondary};
  margin: 20px 0;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;

  & p {
    font-size: ${({ theme }) => theme.font.size.big};
    font-weight: ${({ theme }) => theme.font.weight.regular};
  }
`

const StyledButtonTransparentArrow = styled(ButtonTransparentArrow)``

const BlogGridPost: React.FC<BlogGridPostProps> = ({ node, blogFields }) => (
  <PostWrapper>
    <ImageWrapper>
      <Plaatjie
        image={node.projectdetail?.info?.image}
        alt="Project afbeelding"
      />
    </ImageWrapper>
    <Container className="text-center">
      <div>
        <h4>{node.projectdetail?.info?.name}</h4>
        <h5>{node.projectdetail?.info?.position}</h5>
        <Line />
        <p>{node.projectdetail?.quote}</p>
      </div>
      <div className="pt-5 pb-3 d-flex justify-content-center">
        <div className="mt-2">
          <StyledButtonTransparentArrow to={node.uri}>
            {blogFields.readmoretext}
          </StyledButtonTransparentArrow>
        </div>
      </div>
    </Container>
  </PostWrapper>
)

export default Projects
