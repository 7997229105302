import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { motion, AnimatePresence } from 'framer-motion'

// Images
// import Euro from 'img/euro.inline.svg'
import Employment from 'img/employment.inline.svg'
import Level from 'img/level.inline.svg'
import Cross from 'img/cross.inline.svg'
import E from 'img/e.inline.svg'

// Components
import ParseContent from 'components/shared/ParseContent'
import ButtonTransparentArrow from 'components/elements/Buttons/ButtonTransparentArrow'
import OpenSollicitation from 'components/elements/OpenSollicitation'
import ButtonDefaultArrow from 'components/elements/Buttons/ButtonDefaultArrow'
import ButtonWhiteArrow from 'components/elements/Buttons/ButtonWhiteArrow'
import Blogik, { BlogConsumer, BlogFilter } from 'components/shared/Blogik'

// Third Party
import styled, { css } from 'styled-components'
import Modal from 'react-modal'

// Hooks
import useLayout from 'hooks/useLayout'
import GravityForm from 'components/shared/GravityForm'
import ViewPortAnimatedFingerprint from 'components/elements/FingerPrint'

interface VacanciesProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Blog
  location: any
}

const Amount = styled.div`
  font-weight: ${({ theme }) => theme.font.weight.bold};
  color: ${({ theme }) => theme.color.dark};
`

const Shadow = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  border-radius: 25px;
  transition: all 0.3s ease;
`

const Block = styled.div<{ active: boolean }>`
  position: relative;
  border-radius: 25px;
  background-color: ${({ theme }) => theme.color.light};
  color: ${({ theme }) => theme.color.secondary};
  transition: all 0.3s ease;
  height: 100%;
  cursor: pointer;

  ${Shadow} {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  }

  ${({ active, theme }) =>
    active &&
    css`
      box-shadow: inset 0 0 0 4px ${theme.color.secondary};
      @media (max-width: 991px) {
        box-shadow: inset 0 0 0 4px ${theme.color.primary};
      }
    `}

  & img {
    max-height: 80px;
    @media (max-width: 991px) {
      max-height: 40px;
    }
  }

  &:hover {
    /* box-shadow: inset 0 0 0 4px ${({ theme }) => theme.color.secondary}; */
    ${Shadow} {
      box-shadow: 0 0 0px rgba(0, 0, 0, 0.3);
    }
  }

  @media (max-width: 991px) {
    font-size: ${(props) => props.theme.font.size.medium};
  }
`

const FilterTitle = styled.div`
  font-weight: ${({ theme }) => theme.font.weight.bold};
  color: ${({ theme }) => theme.color.secondary};
`

const ContentWrapper = styled.div`
  @media screen and (max-width: 991px) {
    p {
      margin-bottom: 0;
    }
  }
`

const Alert = styled.div``

const CloseButton = styled.div`
  right: 20px;
  top: 15px;
  & svg {
    width: 30px;
    height: 30px;
    & path {
      fill: #fff;
    }
  }
`

const Content = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    font-size: 45px;
  }
`

const FilterName = styled.span`
  line-height: 20px;
`

const Vacancies: React.FC<VacanciesProps> = ({ fields, location }) => {
  const [mobileFilterOpen, setMobileFilterOpen] = React.useState(false)
  const [alertOpen, setAlertOpen] = React.useState(false)

  const layout = useLayout()

  React.useEffect(() => {
    if (mobileFilterOpen || alertOpen) {
      layout.setIsScrollable(false)
    } else {
      layout.setIsScrollable(true)
    }
  }, [mobileFilterOpen, alertOpen])

  const query = useStaticQuery<GatsbyTypes.getVacTypesQuery>(graphql`
    query getVacTypes {
      allWpVacatureType {
        edges {
          node {
            slug
            id
          }
        }
      }
    }
  `)

  let urlFilter: any = null

  if (location.hash) {
    urlFilter = query.allWpVacatureType.edges.filter(
      (i) => i.node.slug === location.hash.replace('#', '')
    )[0].node.id
  }

  return (
    <section>
      <AlertPopUp isOpen={alertOpen} requestClose={() => setAlertOpen(false)} />
      <Blogik
        type={fields?.viewtype}
        settings={{
          id: `${fields.viewtype}-blogik`,
          limit: 100,
          notIds: [1300],
          type: 'vacatures',
          urlFilter,
        }}
      >
        <div className="py-lg-4 py-2">
          {fields.viewtype === 'blog' && <BlogFilters />}
          {fields.viewtype === 'vacatures' && (
            <>
              <div className="container">
                <BlogConsumer>
                  {({
                    posts,
                    categories,
                    selectedFilters,
                    setSelectedFilters,
                  }: any) => {
                    const setFilter = (id: number) => {
                      setSelectedFilters(id)
                    }

                    return (
                      <div className="row pt-lg-5 pt-3 ">
                        <ContentWrapper className="col-lg-5 mb-5">
                          <Content content={fields.description} />
                        </ContentWrapper>
                        <div className="col-lg-7 mb-5 align-items-center justify-content-end d-lg-flex d-none">
                          <div>
                            <FilterTitle className="pb-4">
                              Filter onze vacatures op functie:
                            </FilterTitle>
                            <div className="row">
                              {categories.map(
                                (category: any, index: number) => (
                                  <div
                                    className="col-md-4 col-6 mb-4 mb-md-0"
                                    // eslint-disable-next-line react/no-array-index-key
                                    key={index}
                                  >
                                    <Block
                                      active={selectedFilters.includes(
                                        category.node.id
                                      )}
                                      onClick={() =>
                                        setFilter(category.node.id)
                                      }
                                      className="d-flex flex-column align-items-center px-lg-5 pt-lg-4 pb-lg-4"
                                    >
                                      <Shadow />
                                      <img
                                        src={
                                          category.node.projecttype.image
                                            .localFile.publicURL
                                        }
                                        alt="Icoon"
                                      />
                                      <FilterName className="mt-auto pt-4 text-center">
                                        {category.node.name}
                                      </FilterName>
                                    </Block>
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-12 py-3">
                          <Alert className="row">
                            <div className="col-lg-8">
                              <div className="h-100 d-flex text-lg-left text-center align-items-center">
                                <ParseContent
                                  content={fields.alertdescription}
                                />
                              </div>
                            </div>
                            <div className="col-lg-4 d-flex justify-content-lg-end justify-content-center pt-lg-0 pt-4">
                              <ButtonDefaultArrow isCustom>
                                <button
                                  type="button"
                                  onClick={() => setAlertOpen(true)}
                                >
                                  {fields.alertlink?.title}
                                </button>
                              </ButtonDefaultArrow>
                            </div>
                          </Alert>
                        </div>
                        <div className="col-12 pt-5">
                          <Amount className="pb-4">{`${
                            posts.filter(
                              (post: BlogGridPostProps) =>
                                post.node.vacaturedetail.showvacature
                            ).length
                          } vacature(s)`}</Amount>
                        </div>
                      </div>
                    )
                  }}
                </BlogConsumer>
              </div>
              <BlogConsumer>
                {({
                  posts,
                  categories,
                  selectedFilters,
                  setSelectedFilters,
                }: any) => {
                  const setFilter = (id: number) => {
                    setSelectedFilters(id)
                  }
                  

                  return (
                    <AnimatePresence>
                      {!mobileFilterOpen && (
                        <MobileBlogFilterCollapsed
                          className="d-lg-none d-flex position-fixed w-100 p-4 p-md-5"
                          style={{ bottom: 0 }}
                          initial={{ y: 200 }}
                          animate={{ y: 0 }}
                          exit={{ y: 200 }}
                          transition={{ type: 'spring', damping: 20 }}
                          onClick={() => setMobileFilterOpen(true)}
                          onKeyDown={() => setMobileFilterOpen(true)}
                          role="button"
                          tabIndex={-1}
                        >
                          <span className="font-weight-bold text-center w-100">
                            Filter {posts.length} vacature(s)
                          </span>
                        </MobileBlogFilterCollapsed>
                      )}
                      {mobileFilterOpen && (
                        <MobileBlogFilterExpanded
                          initial={{ height: '0vh' }}
                          animate={{
                            height: '70vh',
                            transition: { duration: 0.6 },
                          }}
                          exit={{
                            height: '0vh',
                            transition: { duration: 0.6 },
                          }}
                          onClick={() => setMobileFilterOpen(false)}
                          className="position-fixed w-100 p-4"
                        >
                          <CloseButton
                            className="position-absolute"
                            onClick={() => setMobileFilterOpen(false)}
                          >
                            <Cross />
                          </CloseButton>
                          <span className="font-weight-bold w-100 text-center">
                            Filter {posts.length} vacature(s)
                          </span>
                          <div className="mt-5">
                            <div className="row">
                              {categories.map(
                                (category: any, index: number) => (
                                  <div
                                    className="col-sm-4 col-6 mb-4 mb-md-0"
                                    // eslint-disable-next-line react/no-array-index-key
                                    key={index}
                                  >
                                    <Block
                                      active={selectedFilters.includes(
                                        category.node.id
                                      )}
                                      onClick={() =>
                                        setFilter(category.node.id)
                                      }
                                      className="d-flex flex-column align-items-center px-lg-5 px-4 pt-lg-4 pt-3 pb-lg-4 pb-lg-3"
                                    >
                                      <Shadow />
                                      <img
                                        src={
                                          category.node.projecttype.image
                                            .localFile.publicURL
                                        }
                                        alt="Icoon"
                                      />
                                      <span className="mt-auto pt-4">
                                        {category.node.name}
                                      </span>
                                    </Block>
                                  </div>
                                )
                              )}
                            </div>
                            <div className="row mt-5">
                              <div className="col-12">
                                <h2>
                                  Niet de vacature gevonden waar je op hoopte?
                                </h2>
                                <ButtonWhiteArrow to="/vacatures/open-sollicitatie">
                                  Open sollicitatie
                                </ButtonWhiteArrow>
                              </div>
                            </div>
                          </div>
                        </MobileBlogFilterExpanded>
                      )}
                    </AnimatePresence>
                  )
                }}
              </BlogConsumer>
            </>
          )}
          <BlogGrid fields={fields} />
        </div>
      </Blogik>
    </section>
  )
}

const MobileBlogFilterExpanded = styled(motion.div)`
  overflow-y: scroll;
  ${(props) => `
    background: rgb(${props.theme.color.gradient.contrast.to});
    background: linear-gradient(90deg, rgba(${props.theme.color.gradient.contrast.from},1) 0%, rgba(${props.theme.color.gradient.contrast.to},1) 50%);
  `}
  color: ${(props) => props.theme.color.light};
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  z-index: 10;
  bottom: 0;
  @media (min-width: 768px) {
    font-size: ${(props) => props.theme.font.size.mega};
  }
  @media (max-width: 767px) {
    font-size: ${(props) => props.theme.font.size.large};
  }
`

const MobileBlogFilterCollapsed = styled(motion.div)`
  ${(props) => `
    background: rgb(${props.theme.color.gradient.contrast.to});
    background: linear-gradient(90deg, rgba(${props.theme.color.gradient.contrast.from},1) 0%, rgba(${props.theme.color.gradient.contrast.to},1) 50%);
  `}
  font-size: ${(props) => props.theme.font.size.huge};
  color: ${(props) => props.theme.color.light};
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  z-index: 10;
`

const StyledBlogFilter = styled(BlogFilter)<{ selected: boolean }>`
  font-weight: ${(props) =>
    props.selected
      ? props.theme.font.weight.bold
      : props.theme.font.weight.light};
`

interface BlogFiltersProps {}

interface BlogFilterProps {
  node: {
    id: string
    name: string
  }
}

const BlogFilters: React.FC<BlogFiltersProps> = () => (
  <BlogConsumer>
    {({ categories, isSelected }: any) => (
      <div className="container d-none d-md-flex">
        {categories.map((category: BlogFilterProps) => (
          <StyledBlogFilter
            key={category.node.id}
            id={category.node.id}
            selected={isSelected(category.node.id)}
            className="mr-3"
          >
            {category.node.name}
          </StyledBlogFilter>
        ))}
      </div>
    )}
  </BlogConsumer>
)

interface BlogGridProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Blog
}

const BlogGrid: React.FC<BlogGridProps> = ({ fields }) => (
  <BlogConsumer>
    {({ posts }: any) => (
      <div className="container mb-5 pb-5">
        <div className="row">
          {posts
            .filter(
              (post: BlogGridPostProps) => post.node.vacaturedetail.showvacature
            )
            .map((post: BlogGridPostProps) => (
              <>
                {post.node.databaseId !== 626 ? (
                  <div key={post.node.id} className="col-12 py-3">
                    <BlogGridPost node={post.node} blogFields={fields} />
                  </div>
                ) : (
                  <div key="open-sollicitation" className="col-12 py-3">
                    <OpenSollicitation node={post.node} />
                  </div>
                )}
              </>
            ))}
        </div>
        {/*
        {showMoreButton && (
          <div className="mt-5 text-center">
            <ButtonDefault to="/" isCustom>
              <BlogButton>{fields.loadMoreText}</BlogButton>
            </ButtonDefault>
          </div>
        )} */}
      </div>
    )}
  </BlogConsumer>
)

const PostWrapper = styled.div`
  background: ${(props) => props.theme.color.light};
  border-radius: 20px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  padding: 40px 100px;

  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    color: ${(props) => props.theme.color.secondary};
    padding-bottom: 20px;
  }

  & p {
    line-height: 30px;
    font-weight: ${({ theme }) => theme.font.weight.light};
    color: ${(props) => props.theme.color.alternate};
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;

    @media (min-width: 1200px) {
      -webkit-line-clamp: 5;
    }

    @media (max-width: 1199px) {
      -webkit-line-clamp: 4;
    }
  }

  @media screen and (max-width: 991px) {
    padding: 20px 15px;
  }
`

interface BlogGridPostProps {
  // eslint-disable-next-line
  blogFields: GatsbyTypes.WpPage_Flexcontent_Flex_Blog
  node: {
    id: string
    title: string
    uri: string
    salary: string
    databaseId: number
    vacaturedetail: {
      showvacature: boolean
      info: {
        jobdescription: string
        level: string
        employment: string
        salary: string
      }
    }
    vacatureTypes: any
  }
}

const StyledButtonTransparentArrow = styled(ButtonTransparentArrow)``

const Info = styled.div`
  color: ${({ theme }) => theme.color.alternate};
  font-weight: ${({ theme }) => theme.font.weight.light};
`

const ButtonWrapper = styled.div`
  @media screen and (max-width: 991px) {
    width: 100%;
  }
`

const BlogGridPost: React.FC<BlogGridPostProps> = ({ node, blogFields }) => {
  const { info } = node.vacaturedetail
  const type = node.vacatureTypes.nodes[0].name

  return (
    <PostWrapper className="mb-4">
      <h4>{node.title}</h4>
      <p>{info?.jobdescription}</p>
      <div className="d-flex flex-lg-nowrap flex-wrap justify-content-between">
        <Info className="d-flex flex-column flex-sm-row">
          <div className="d-flex align-items-center mr-5">
            <Level />
            <div className="ml-2">{info?.level}</div>
          </div>
          <div className="d-flex align-items-center mr-5">
            <Employment />
            <div className="ml-2">{info?.employment}</div>
          </div>
          <div className="d-flex align-items-center">
            <E />
            <div className="ml-2">{type}</div>
          </div>
        </Info>
        <ButtonWrapper className="mt-lg-2 mt-4 d-flex justify-content-end">
          <StyledButtonTransparentArrow to={node.uri}>
            {blogFields.readmoretext}
          </StyledButtonTransparentArrow>
        </ButtonWrapper>
      </div>
    </PostWrapper>
  )
}

const AlertPopUpWrapper = styled(Modal)`
  @media (min-width: 992px) {
    height: 40%;
    width: 50%;
  }
  @media (max-width: 991px) {
    height: 40%;
    width: 90%;
  }
`

const FormWrapper = styled.div`
  background: rgb(${(props) => props.theme.color.gradient.contrast.to});
  background: linear-gradient(
    135deg,
    rgba(${(props) => props.theme.color.gradient.contrast.from}, 1) 0%,
    rgba(${(props) => props.theme.color.gradient.contrast.to}, 1) 45%
  );
  box-shadow: 0 0 14px rgba(0, 0, 0, 0.35);
  padding: 70px 70px 70px 70px;
  margin-top: -140px;
  border-radius: 12px;
  overflow-y: scroll;

  @media (max-width: 991px) {
    overflow-y: auto !important;
    max-height: calc(94vh - 10px);
  }

  & form {
    & .form-group > label {
      color: ${({ theme }) => theme.color.light} !important;
    }
  }

  & form {
    & .alert-form {
      & .form-group > label > a:hover {
        color: black !important;
      }
    }
  }

  & .fingerprint {
    & svg {
      opacity: 0.1;
    }
  }

  & a {
    text-decoration: underline;

    &:hover {
      color: ${({ theme }) => theme.color.secondary};
    }
    & img:hover {
      opacity: 0.82;
    }
  }

  @media screen and (max-width: 991px) {
    padding: 30px 30px 70px 30px;
  }
`

interface AlertPopUpProps {
  // eslint-disable-next-line
  isOpen: boolean
  requestClose: Function
}

const AlertPopUp: React.FC<AlertPopUpProps> = ({ isOpen, requestClose }) => {
  const customStyles = {
    content: {
      position: 'relative',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  }

  return (
    <AnimatePresence>
      <motion.div
        initial={{ y: 200 }}
        animate={{ y: 0 }}
        exit={{ y: 200 }}
        transition={{ type: 'spring', damping: 20 }}
      >
        <AlertPopUpWrapper
          isOpen={isOpen}
          onRequestClose={requestClose}
          style={customStyles}
        >
          <FormWrapper>
            {/* <CloseButton
          className="position-absolute"
          onClick={() => requestClose}
          style={{ zIndex: 999 }}
        >
          <Cross />
        </CloseButton> */}
            <div className="position-absolute fingerprint">
              <ViewPortAnimatedFingerprint />
            </div>
            <GravityForm id={4} />
          </FormWrapper>
        </AlertPopUpWrapper>
      </motion.div>
    </AnimatePresence>
  )
}

export default Vacancies
