import React from 'react'

// Components
import ButtonTransparentArrow from 'components/elements/Buttons/ButtonTransparentArrow'
import ParseContent from 'components/shared/ParseContent'

import Blogik, { BlogConsumer } from 'components/shared/Blogik'

// UBO
import Plaatjie from '@ubo/plaatjie'

// Third Party
import styled from 'styled-components'

// Hooks
import useNews from 'hooks/useNews'

interface BlogProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Blog
  location: any
}

const Title = styled.div`
  text-align: center;
  font-size: 50px;
  font-family: ${({ theme }) => theme.font.family.secondary};
  color: ${({ theme }) => theme.color.alternate};
`

const OtherNews: React.FC<BlogProps> = ({ fields }) => {
  const { id }: any = useNews()

  return (
    <section className="py-5">
      <Title className="pt-2 pb-4">Ander nieuws</Title>
      <Blogik
        type={fields?.viewtype}
        settings={{
          id: `${fields.viewtype}-blogik`,
          limit: Infinity,
          type: 'blog',
          notIds: [id],
        }}
      >
        <div className="py-4">
          <BlogGrid fields={fields} />
        </div>
      </Blogik>
    </section>
  )
}

interface BlogGridProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Blog
}

const BlogGrid: React.FC<BlogGridProps> = ({ fields }) => (
  <BlogConsumer>
    {({ posts }: any) => (
      <div className="container">
        <div className="row">
          {posts[0] && (
            <div key={posts[0].node.id} className="col-lg-4 py-3">
              <BlogGridPost node={posts[0].node} blogFields={fields} />
            </div>
          )}
          {posts[1] && (
            <div key={posts[1].node.id} className="col-lg-4 py-3">
              <BlogGridPost node={posts[1].node} blogFields={fields} />
            </div>
          )}
          {posts[2] && (
            <div key={posts[2].node.id} className="col-lg-4 py-3">
              <BlogGridPost node={posts[2].node} blogFields={fields} />
            </div>
          )}
        </div>
      </div>
    )}
  </BlogConsumer>
)

const PostWrapper = styled.div`
  background: ${(props) => props.theme.color.light};
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  padding: 20px 40px;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    color: ${(props) => props.theme.color.secondary};
    padding-bottom: 20px;
    font-size: ${({ theme }) => theme.font.size.large};
  }

  & p {
    line-height: 30px;
    font-weight: ${({ theme }) => theme.font.weight.light};
    color: ${(props) => props.theme.color.alternate};
  }

  @media screen and (max-width: 991px) {
    min-height: 300px;
  }
`

interface BlogGridPostProps {
  // eslint-disable-next-line
  blogFields: GatsbyTypes.WpPage_Flexcontent_Flex_Blog
  node: GatsbyTypes.generalPostFragmentFragment
}

const ReadTime = styled.div`
  text-align: right;
  font-family: ${({ theme }) => theme.font.family.secondary};
  font-size: 14px;
  color: ${({ theme }) => theme.color.grey};
`

const ImageWrapper = styled.div`
  height: 220px;
  width: 100%;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
`

const StyledButtonTransparentArrow = styled(ButtonTransparentArrow)``

const BlogGridPost: React.FC<BlogGridPostProps> = ({ node, blogFields }) => {
  const { info } = node.newsdetail!

  return (
    <>
      <ImageWrapper>
        <Plaatjie image={info?.image} alt="Ander Nieuws Afbeelding" />
      </ImageWrapper>
      <PostWrapper>
        <div>
          <ReadTime className="pb-3">{info?.readtime}</ReadTime>
          <h3>{node.title}</h3>
          <ParseContent content={info?.description} />
        </div>
        <div className="d-flex h-100 align-items-end justify-content-end">
          <div className="mt-4">
            <StyledButtonTransparentArrow to={node.uri}>
              {blogFields.readmoretext}
            </StyledButtonTransparentArrow>
          </div>
        </div>
      </PostWrapper>
    </>
  )
}

export default OtherNews
