import React from 'react'

// Components
import ButtonTransparentArrow from 'components/elements/Buttons/ButtonTransparentArrow'
import ParseContent from 'components/shared/ParseContent'

import Blogik, { BlogConsumer } from 'components/shared/Blogik'

// UBO
import Plaatjie from '@ubo/plaatjie'

// Third Party
import styled from 'styled-components'

interface BlogProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Blog
  location: any
}

const News: React.FC<BlogProps> = ({ fields }) => (
  <section>
    <Blogik
      type={fields?.viewtype}
      settings={{
        id: `${fields.viewtype}-blogik`,
        limit: Number(fields.limit),
        type: 'blog',
      }}
    >
      <div className="py-4">
        <BlogGrid fields={fields} />
      </div>
    </Blogik>
  </section>
)

interface BlogGridProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Blog
}

const BlogGrid: React.FC<BlogGridProps> = ({ fields }) => (
  <BlogConsumer>
    {({ posts }: any) => (
      <div className="container mb-5 pb-5">
        <div className="row">
          {posts.map((post: BlogGridPostProps) => (
            <>
              <div
                key={post.node.id}
                className="col-lg-4 col-md-6 d-flex flex-column flex-grow-1 py-3"
              >
                <BlogGridPost node={post.node} blogFields={fields} />
              </div>
            </>
          ))}
        </div>
        {/*
        {showMoreButton && (
          <div className="mt-5 text-center">
            <ButtonDefault to="/" isCustom>
              <BlogButton>{fields.loadMoreText}</BlogButton>
            </ButtonDefault>
          </div>
        )} */}
      </div>
    )}
  </BlogConsumer>
)

const PostWrapper = styled.div`
  background: ${(props) => props.theme.color.light};
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  padding: 20px 40px;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    color: ${(props) => props.theme.color.secondary};
    padding-bottom: 20px;
  }

  & p {
    line-height: 30px;
    font-weight: ${({ theme }) => theme.font.weight.light};
    color: ${(props) => props.theme.color.alternate};
  }

  @media screen and (max-width: 991px) {
    min-height: 300px;
  }
`

interface BlogGridPostProps {
  // eslint-disable-next-line
  blogFields: GatsbyTypes.WpPage_Flexcontent_Flex_Blog
  node: GatsbyTypes.generalPostFragmentFragment
}

const ReadTime = styled.div`
  text-align: right;
  font-family: ${({ theme }) => theme.font.family.secondary};
  font-size: 14px;
  color: ${({ theme }) => theme.color.dark};
`

const ImageWrapper = styled.div`
  height: 220px;
  width: 100%;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);

  > div {
    height: 100%;
    width: 100%;
  }
`

const Content = styled(ParseContent)`
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
`

const StyledButtonTransparentArrow = styled(ButtonTransparentArrow)``

const BlogGridPost: React.FC<BlogGridPostProps> = ({ node, blogFields }) => {
  const { info }: any = node.newsdetail

  return (
    <>
      <ImageWrapper>
        <Plaatjie image={info?.image} alt="Nieuws afbeelding" />
      </ImageWrapper>
      <PostWrapper>
        <div>
          <ReadTime className="pb-3">{info?.readtime}</ReadTime>
          <h4>{node.title}</h4>
          <Content content={info?.description} />
        </div>
        <div className="d-flex h-100 align-items-end justify-content-end">
          <div className="mt-4">
            <StyledButtonTransparentArrow to={node.uri}>
              {blogFields.readmoretext}
            </StyledButtonTransparentArrow>
          </div>
        </div>
      </PostWrapper>
    </>
  )
}

export default News
