import ParseContent from 'components/shared/ParseContent'
import React from 'react'

// Third Party
import styled, { css } from 'styled-components'

// Components
import ButtonWhiteArrow from 'components/elements/Buttons/ButtonWhiteArrow'

interface Props {
  node: {
    id: string
    title: string
    uri: string
    salary: string
    databaseId: number
    vacaturedetail: {
      info: {
        jobdescription: string
        level: string
        employment: string
        salary: string
      }
    }
  }
}

const StyledOpen = styled.div`
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  border-radius: 20px;
  padding: 60px 100px;
  color: ${({ theme }) => theme.color.light};

  ${({ theme }) => css`
    background: ${theme.color.gradient.contrast.to};
    background: ${`rgb(${theme.color.gradient.to})`};
    background: ${`linear-gradient(90deg, rgba(${theme.color.gradient.contrast.from},1) 0%, rgba(${theme.color.gradient.contrast.to},1) 35%);`};
  `}

  @media screen and (max-width: 991px) {
    padding: 20px 15px;
  }
`

const Content = styled(ParseContent)`
  font-weight: ${({ theme }) => theme.font.weight.bold};
  line-height: 30px;
`

const Title = styled.div`
  font-family: ${({ theme }) => theme.font.family.secondary};
  font-size: 40px;
  line-height: 48px;
  @media (max-width: 575px) {
    font-size: 24px;
  }
`

const OpenSollicitation: React.FC<Props> = ({ node }) => (
  <StyledOpen>
    <div className="d-flex flex-lg-nowrap flex-wrap pb-lg-5 pb-3 align-items-center justify-content-between">
      <Title>{node.title}</Title>
      <ButtonWhiteArrow className="mt-lg-0 mt-3" to={node.uri}>
        Bekijken
      </ButtonWhiteArrow>
    </div>

    <Content content={node.vacaturedetail.info.jobdescription} />
  </StyledOpen>
)

export default OpenSollicitation
