import React from 'react'

// Components
import News from 'components/flex/Blog/News'
import Vacancies from 'components/flex/Blog/Vacancies'
import Projects from 'components/flex/Blog/Projects'
import OtherNews from 'components/flex/Blog/OtherNews'
import OtherProjects from 'components/flex/Blog/OtherProjects'
import RelatedProjects from 'components/flex/Blog/RelatedProjects'

interface BlogShellProps {
  fields: any
  location?: any
}

interface BlogProps {
  [key: string]: any
}

const BlogShell: React.FC<BlogShellProps> = ({ fields, location = {} }) => {
  const types: BlogProps = {
    nieuws: News,
    vacatures: Vacancies,
    projecten: Projects,
    other_news: OtherNews,
    other_projects: OtherProjects,
    related_projects: RelatedProjects
  }

  if (!fields.viewtype || !types[fields.viewtype]) {
    return null
  }

  const Component = types[fields.viewtype]

  return (
    <Component
      key={`${location ? location.pathname : 'flex'}`}
      fields={fields}
      location={location}
    />
  )
}

export default BlogShell
