import React from 'react'

// Components
import ButtonTransparentArrow from 'components/elements/Buttons/ButtonTransparentArrow'

// UBO
import Plaatjie from '@ubo/plaatjie'

import Blogik, { BlogConsumer } from 'components/shared/Blogik'

// Third Party
import styled from 'styled-components'
import useProject from 'hooks/useProject'

interface ProjectsProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Blog
  location: any
}

const Title = styled.div`
  text-align: center;
  font-size: 50px;
  font-family: ${({ theme }) => theme.font.family.secondary};
  color: ${({ theme }) => theme.color.alternate};
`

const RelatedProjects: React.FC<ProjectsProps> = ({ fields }) => {
  const { id }: any = useProject()  

  return (
    <section>
      <Blogik
        type={fields?.viewtype}
        settings={{
          id: `${fields.viewtype}-blogik`,
          postIds: [fields.selectProjects![0]?.databaseId || 0],
          limit: Infinity,
          type: 'projecten',
        }}
      >
        <div className="py-4">
          <BlogGrid fields={fields} />
        </div>
      </Blogik>
    </section>
  )
}

interface BlogGridProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Blog
}

const BlogGrid: React.FC<BlogGridProps> = ({ fields }) => (
  <BlogConsumer>
    {({ posts }: any) => (
      <>
        {posts.length > 1 ? (
          <>
            <Title className="pb-5">Gerelateerde projecten</Title>

            <div className="container">
              <div className="row">
                {posts[0] && (
                  <div key={posts[0].node.id} className="col-lg-6 py-3">
                    <BlogGridPost node={posts[0].node} blogFields={fields} />
                  </div>
                )}

                {posts[1] && (
                  <div key={posts[1].node.id} className="col-lg-6 py-3">
                    <BlogGridPost node={posts[1].node} blogFields={fields} />
                  </div>
                )}
              </div>
            </div>
          </>
        ) : (
          <>
            <Title className="pb-5">Gerelateerde projecten</Title>

            <div className="container">
              <div className="row">
                {posts[0] && (
                  <div key={posts[0].node.id} className="col-lg-6 py-3">
                    <BlogGridPost node={posts[0].node} blogFields={fields} />
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </>
    )}
  </BlogConsumer>
)

const PostWrapper = styled.div`
  background: ${(props) => props.theme.color.light};
  border-radius: 20px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  padding: 140px 80px 20px 80px;
  margin: 100px 40px 40px 40px;

  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    color: ${(props) => props.theme.color.secondary};
  }

  & h4 {
    font-size: 20px;
    font-family: ${({ theme }) => theme.font.family.main};
    font-weight: ${({ theme }) => theme.font.weight.extraBold};
  }

  & h5 {
    font-size: 16px;
    font-family: ${({ theme }) => theme.font.family.main};
  }

  & p {
    line-height: 30px;
    font-weight: ${({ theme }) => theme.font.weight.light};
    font-family: ${({ theme }) => theme.font.family.secondary};
    color: ${(props) => props.theme.color.alternate};
  }

  @media screen and (max-width: 991px) {
    margin: 100px 20px 20px 20px;
  }

  @media screen and (max-width: 575px) {
    padding: 140px 40px 20px 40px;
  }

  @media screen and (max-width: 350px) {
    margin: 100px 0 20px 0;
    padding: 140px 20px 10px 20px;
  }
`

interface BlogGridPostProps {
  // eslint-disable-next-line
  blogFields: GatsbyTypes.WpPage_Flexcontent_Flex_Blog
  node: GatsbyTypes.WpProject
}

const ImageWrapper = styled.div`
  display: flex;
  width: 220px;
  height: 220px;
  position: relative;
  border: 5px solid ${({ theme }) => theme.color.secondary};
  background-color: ${({ theme }) => theme.color.light};
  border-radius: 110px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;

  & img {
    border-radius: 110px;
  }
`

const Line = styled.div`
  height: 1px;
  background-color: ${({ theme }) => theme.color.secondary};
  margin: 20px 0;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  & p {
    font-size: ${({ theme }) => theme.font.size.big};
    font-weight: ${({ theme }) => theme.font.weight.regular};
  }
`

const StyledButtonTransparentArrow = styled(ButtonTransparentArrow)``

const BlogGridPost: React.FC<BlogGridPostProps> = ({ node, blogFields }) => (
  <PostWrapper>
    <ImageWrapper>
      <Plaatjie
        image={node.projectdetail?.info?.image}
        alt="Ander project afbeelding"
      />
    </ImageWrapper>
    <Container className="text-center">
      <div>
        <h4>{node.projectdetail?.info?.name}</h4>
        <h5>{node.projectdetail?.info?.position}</h5>
        <Line />
        <p>{node.projectdetail?.quote}</p>
      </div>
      <div className="pt-5 pb-3 d-flex justify-content-center">
        <div className="mt-2">
          <StyledButtonTransparentArrow to={node.uri}>
            {blogFields.readmoretext}
          </StyledButtonTransparentArrow>
        </div>
      </div>
    </Container>
  </PostWrapper>
)

export default RelatedProjects
